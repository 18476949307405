import { Logger, ConsoleOutput, LokiHttpOutput } from '@peterholak/logjsloki'

function createLogger() {
    const loggerUrl = window.localStorage.getItem('loggerUrl') ?? '' // 'http://localhost:3101/loki/api/v1/push'
    const loggerAuth = window.localStorage.getItem('loggerAuth')
    const loggerAuthHeaders = loggerAuth !== null ?
        { 'Authorization': loggerAuth } :
        {} as {}

    return new Logger('authweb-frontend', 'info', [
        new ConsoleOutput(),
        ...(loggerUrl !== '' ? [new LokiHttpOutput(loggerUrl, true, fetch as any, loggerAuthHeaders)] : [])
    ])
}

export const logger = createLogger()
