import React, { useEffect, useState } from 'react'
import { logger } from '../logger'

type SessionData = {
    accessToken?: string,
    authUrl: string
}

export type TokenPairMessage = {
    kind: 'token'
    accessToken: string
    refreshToken: string
}

export type CompleteLoginMessage = {
    kind: 'complete-login'
    code: string
    state: string
}

export type LogoutMessage = {
    kind: 'logout'
}

export type WebsiteContentScriptOutgoing =
    TokenPairMessage |
    LogoutMessage |
    CompleteLoginMessage
    

function postToSelfAndExtension(message: WebsiteContentScriptOutgoing) {
    if (window.opener !== null) {
        // probably opened from webapp
        logger.info(`posting message to opener: ${JSON.stringify(message)}`)
        window.opener?.postMessage(message, '*')
    }else{
        // probably opened from extension
        window.postMessage(message, window.origin)
    }   
}

export default function App() {
    const [sessionData, setSessionData] = useState<SessionData>()
    useEffect(() => {
        fetch('/session')
            .then(res => res.json())
            .then(json => {
                logger.info(JSON.stringify(json, null, 2))
                setSessionData(json)
                // if (json.accessToken !== undefined) {
                //     postToSelfAndExtension({kind: 'token', accessToken: json.accessToken, refreshToken: json.refreshToken})
                // } else {
                //     // TODO: this should only be sent after an explicit logout maybe
                //     // otherwise it can get screwed up in incognito windows, etc.
                //     postToSelfAndExtension({ kind: 'logout' })
                // }
                if (json.extensionCode !== undefined) {
                    postToSelfAndExtension({ kind: 'complete-login', code: json.extensionCode, state: json.extensionState })
                }
            })
            .catch(e => {
                logger.error(e)
                setSessionData({accessToken:'error token', authUrl: sessionData?.authUrl ?? ''})
            })
    }, [])
    return <div>
        Hello<br />
        {sessionData?.accessToken ?
            <>
                {sessionData.accessToken}
                <br />
                <a href="/logout">logout</a> <a href="/account">account</a>
            </>
            :
            <a href={sessionData?.authUrl}>login</a>
        }
        <div>
            <span>-----</span>
            <pre>
                {JSON.stringify(sessionData, null, 2)}
            </pre>
        </div>
    </div>
}
